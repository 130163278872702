// extracted by mini-css-extract-plugin
export var FlexContainer = "Consulting_Services-module--FlexContainer--JtETT";
export var FlexItem = "Consulting_Services-module--FlexItem--oxd5b";
export var back = "Consulting_Services-module--back--OzIWC";
export var img_back = "Consulting_Services-module--img_back--ABUzS";
export var img_group = "Consulting_Services-module--img_group--D8DiV";
export var img_group2 = "Consulting_Services-module--img_group2--WK61-";
export var main = "Consulting_Services-module--main--Kv5od";
export var outer = "Consulting_Services-module--outer--zT083";
export var title = "Consulting_Services-module--title--qq9Ew";
export var wrap = "Consulting_Services-module--wrap--PNZTc";