// extracted by mini-css-extract-plugin
export var FlexContainer = "DesignStream-module--FlexContainer--8XbzV";
export var FlexItem = "DesignStream-module--FlexItem--6tTr3";
export var blurDiv = "DesignStream-module--blurDiv--b0Yke";
export var btn_row = "DesignStream-module--btn_row--bSgSO";
export var btn_row_p = "DesignStream-module--btn_row_p--rQMYO";
export var hero_btn = "DesignStream-module--hero_btn--VlnYL";
export var img_group = "DesignStream-module--img_group--SIUpz";
export var img_icon = "DesignStream-module--img_icon--vTzBo";
export var img_style = "DesignStream-module--img_style--ack8C";
export var img_title = "DesignStream-module--img_title--lA-Hx";
export var text = "DesignStream-module--text--+Br4V";
export var title = "DesignStream-module--title--MuaSU";
export var wrap = "DesignStream-module--wrap--4N2Zh";