import React, { Component } from 'react';
import * as cs from "./AgileStream.module.css"

// import Img1 from "../../../images/AgileStream.webp"
import Img1 from "../../../images/px-AgileCard.webp"

import ImgIcon from "../../../images/Icon2.webp"

import agileStreamPDF from '../../../../static/agileSTREAM.pdf' 
import { Link,navigate } from 'gatsby';
import Agilestream from "../../../images/AgileStream.webp"
import cx from 'classnames';

const AgileStream = ({updateState}) => {
    return (

        <div className={cs.wrap}  data-aos="fade-up" data-aos-duration="1500"  id="agileStream" >

            <div className={cs.FlexContainer} >
                <div className={cs.FlexItem} >
                    <img src={Img1} className={cs.img_style} />
                    <div className={cs.btn_row} >
                        <div className={cs.hero_btn} ><a  target="_blank" href={agileStreamPDF}  >Service Details</a></div>
                        <p className={cs.btn_row_p}  onClick={()=> updateState("technical_consulting")} > <img src={ImgIcon} className={cs.img_icon} />   Technical Consulting​</p>
                    </div>
                </div>
                <div className={cx(cs.FlexItem, cs.blurDiv)} >
                    {/* <p className={cs.title} >agileSTREAM –  How ready and capable your organization is today for any change?</p> */}
                    <img src={Agilestream} className={cs.img_title} />
                    <ul>
                        <li>How ready and capable your organization is today for any change.</li>
                        <li>Get your organization agility profile leading to a certification.</li>
                        <li>It is an independent, detailed snapshot of your business agility capabilities and behavior.</li>
                        <li>Focusing on customer centricity, growth,  innovation and high performance. </li>
                    </ul>


                </div>


            </div>
        </div>

    );
};

export default AgileStream;