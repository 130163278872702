import * as React from "react"
import Consulting_Services from "../components/Consulting_Services/Consulting_Services"
import SEO from "../components/gatsby/seo"

const Consult_Page = ({ location }) => {
  return (
    <React.Fragment>
      <SEO title="Timestream Group"
        ogtitle="Timestream Group"
        description="Timestream Management and Solutions FZCO is a start-up software and services company registered in Dubai Free zone with the aim of providing quality industry agnostic digital solutions to enable organizations streamline operations and improve decision making."
        url="/services/"
        image="https://timestreamgroup.com/timestream_icon.png" />
      <Consulting_Services location={location} />
    </React.Fragment>

  )
}

export default Consult_Page;