import React, { Component } from 'react';
import * as cs from "./DesignStream.module.css"
// import Img1 from "../../../images/designStream.webp"
import Img1 from "../../../images/px-DesignCard.webp"
import ImgIcon from "../../../images/Icon2.webp"
import { Link,navigate } from 'gatsby';
import Designstream from "../../../images/designStream.webp"
import designStreamPDF from '../../../../static/designSTREAM.pdf' 
import cx from 'classnames';


const DesignStream = ({updateState}) => {
    return (

        <div className={cs.wrap}  data-aos="fade-up" data-aos-duration="1500"  id="designStream" >

            <div className={cs.FlexContainer} >

                <div   className={cx(cs.FlexItem, cs.blurDiv)}  >
                <img src={Designstream} className={cs.img_title} />
                    <ul>
                        <li>A comprehensive Business Design optimization and re-engineering service.</li>
                        <li>End to end Digital branch consulting service that build your future banking targeting Generation Z.​</li>
                        <li>Build a financial feasibility  plan for the transformation of traditional to digital​</li>
                        <li>Run Design thinking to ideate the models depending upon country, city, area, type and target customer</li>
                        <li>Select the technology that meets your consumer needs and integrate with your digital branch design</li>
                        <li>Build the model branches and run for trial</li>
                        <li>Prepare the successful deployment plan based on approved and fine tune models and process.</li>
                    </ul>


                </div>

                <div className={cs.FlexItem} >
                    <img src={Img1} className={cs.img_style} />
                    <div className={cs.btn_row} >
                        <div className={cs.hero_btn} ><a  target="_blank" href={designStreamPDF} >Service Details</a></div>
                        <p className={cs.btn_row_p}  onClick={()=> updateState("managment_consulting")} > <img src={ImgIcon} className={cs.img_icon} />   Managment Consulting​</p>
                    </div>
                </div>


            </div>
        </div>

    );
};

export default DesignStream;