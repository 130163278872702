import React, { Component } from 'react';
import * as cs from "./SecureStream.module.css"
// import Img1 from "../../../images/secureStream.webp"
import Img1 from "../../../images/px-SecureCard.webp"
import ImgIcon from "../../../images/Icon2.webp"

import secureStreamPDF from '../../../../static/secureSTREAM.pdf' 
import { Link,navigate } from 'gatsby';
import securestream from "../../../images/secureStream.webp"
import cx from 'classnames';

const SecureStream = ({updateState}) => {
    return (

        <div className={cs.wrap}  data-aos="fade-up" data-aos-duration="1500"  id="secureStream" >

            <div className={cs.FlexContainer} >
                <div className={cs.FlexItem} >
                    <img src={Img1} className={cs.img_style} />
                    <div className={cs.btn_row} >
                        <div className={cs.hero_btn} ><a  target="_blank" href={secureStreamPDF} >Service Details</a></div>
                        <p className={cs.btn_row_p} onClick={()=> updateState("managment_consulting")}> <img src={ImgIcon} className={cs.img_icon} />   Management Consulting​</p>
                    </div>
                </div>

                <div className={cx(cs.FlexItem, cs.blurDiv)}  >
                    <img src={securestream} className={cs.img_title} />
                    <ul>
                        <li>Security is not an option anymore; security comes first to protect your customers and thier trust on your brand image.</li>
                        <li>Security comes first to protect your customers and their trust on your brand Image.</li>
                        <li>Build a regular external security audit service for your customer's touchpoints, both on customer facing and backend systems & infrastructure. ​</li>
                        <li>OR, use it under Security as a Service from the top security consultant with global market experience.</li>
                    </ul>


                </div>


            </div>
        </div>

    );
};

export default SecureStream;