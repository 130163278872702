import React, { Component } from 'react';
import * as cs from "./SrcStream.module.css"

// import Img1 from "../../../images/AgileStream.webp"
import Img1 from "../../../images/px-SrcCard2.webp"

import ImgIcon from "../../../images/Icon2.webp"

import srcStreamPDF from '../../../../static/scrSTREAM.pdf' 
import { Link,navigate } from 'gatsby';
import Srcstream from "../../../images/SrcStream.webp"
import cx from 'classnames';

const SrcStream = ({updateState}) => {
    return (

        <div className={cs.wrap}  data-aos="fade-up" data-aos-duration="1500"  id="scrStream" >

            <div className={cs.FlexContainer} >
                <div className={cs.FlexItem} >
                    <img src={Img1} className={cs.img_style} />
                    <div className={cs.btn_row} >
                        <div className={cs.hero_btn} ><a  target="_blank" href={srcStreamPDF}  >Service Details</a></div>
                        <p className={cs.btn_row_p}  onClick={()=> updateState("technical_consulting")} > <img src={ImgIcon} className={cs.img_icon} />   Technical Consulting​</p>
                    </div>
                </div>
                <div className={cx(cs.FlexItem, cs.blurDiv)} >
                    <p className={cs.title} >Supply Chain resilience through integrated logistics consultation and digitalization,  targeted towards</p>
                    <img src={Srcstream} className={cs.img_title} />
                    <ul>
                        {/* <li>Supply Chain resilience through integrated logistics consultation and digitalization,  targeted towards</li> */}
                        <li><strong>Government Agencies</strong> - Streamlined end-to-end logistics give sustainability through increased visibility, more control and further agility.</li>
                        <li><strong>Port Operators</strong> - Data and application integration with partners and use of IoT though our suggested digitalization platform will facilitate ports operation assessment in near real-time.</li>
                        <li><strong>Logistics Companies</strong> - Complete supply chain visibility companies can identify the impact of disruptions quickly and respond by changing connections and find new warehousing and distribution options that circumvent issues.</li>
                        <li><strong>All organizations that procure and supply goods globally </strong>- Understanding what is happening from one end of the supply chain to the other is key to making crucial decisions in  supply chain planning.</li>
                        {/* <li>Significant cost savings of freight cost through HashMove  Enterprise Logistics Platform</li> */}
                    </ul>
                    <p className={cs.title2} >Significant cost savings of freight cost through HashMove  Enterprise Logistics Platform</p>

                </div>


            </div>
        </div>

    );
};

export default SrcStream;