import React, { Component } from 'react';
import * as cs from "./ProcessStream.module.css"
// import Img1 from "../../../images/processStream.webp"
import Img1 from "../../../images/px-ProcessCard.webp"
import ImgIcon from "../../../images/Icon2.webp"
import { Link,navigate } from 'gatsby';
import processStreamPDF from '../../../../static/ProcessSTREAM.pdf' 
import Processstream from "../../../images/processStream.webp"
import cx from 'classnames';

const ProcessStream = ({updateState}) => {
    return (

        <div className={cs.wrap}  data-aos="fade-up" data-aos-duration="1500"  id="processStream" >

            <div className={cs.FlexContainer} >

                <div  className={cx(cs.FlexItem, cs.blurDiv)} >
                <img src={Processstream} className={cs.img_title} />
                    <ul>
                    <li>A comprehensive Business process optimization and re-engineering service.​</li>
                        <li>Our practice is built upon BPM discipline.​</li>
                        <li>Business process management (BPM) is the discipline in which people use various methods to discover, model, analyze, measure, improve, optimize, and automate business processes.</li>
                        <li>Use of BPM enabling technologies targeted towards reduction in overall process time and cost; increase productivity and efficiency, based on BPM, Process Mining and Robotic Process Automation (RPA) concepts.</li>
                        <li>Leveraging IT Investment and Digital Transformation for hyperautomation</li>
                    </ul>


                </div>

                <div className={cs.FlexItem} >
                    <img src={Img1} className={cs.img_style} />
                    <div className={cs.btn_row} >
                        <div className={cs.hero_btn} ><a  target="_blank" href={processStreamPDF} >Service Details</a></div>
                        <p className={cs.btn_row_p}  onClick={()=> updateState("technical_consulting")} > <img src={ImgIcon} className={cs.img_icon} />   Technical Consulting​</p>
                    </div>
                </div>


            </div>
        </div>

    );
};

export default ProcessStream;