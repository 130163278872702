import React, { Component,useEffect } from 'react';
import * as cs from "./Consulting_Services.module.css"

import Img_Back from "../../images/back.webp"
import Footer from "../Footer/Footer"
import Header from '../Header/Main_Header';
import { navigate } from 'gatsby';
import AgileStream from './AgileStream/AgileStream';
import ProcessStream from './ProcessStream/ProcessStream';
import SecureStream from './SecureStream/SecureStream';
import DesignStream from './DesignStream/DesignStream';

import Img1 from "../../images/AgileStream.webp"
import Img2 from "../../images/processStream.webp"
import Img3 from "../../images/secureStream.webp"
import Img4 from "../../images/designStream.webp"
import Img5 from "../../images/srcStream.webp"
import Top from "../../images/top_arrow.png"
import SrcStream from './SrcStream/SrcStream';


const Consulting_Services = ({location}) => {
    const [selected, setselected] = React.useState("managment_consulting")
    // console.log("Location", location.state.select_option )
    useEffect(() => {
        if (location.state !== "undefined"  && location.state) {
            setselected(location.state.select_option)
        }
    }, [])


    function updateState(state){
        setselected(state)
    }

    return (
        <>
        <Header />
        <div className={cs.outer} >
            <div className={cs.back} onClick={()=>navigate("/")}  >
            <img src={Img_Back} className={cs.img_back} />
            <p>Back</p>
            </div>
            <div className={cs.main}>
                {selected === "managment_consulting" ? <h1>Management Consulting​</h1> : <h1>Technical Consulting​​</h1>}
                
                <div className={cs.wrap} >
                    <div className={cs.FlexContainer} >

                {selected === "managment_consulting" ? 
                <>
                    <div className={cs.FlexItem}  onClick={()=>navigate("/services/#agileStream")}  >
                    {/* <p className={cs.title} >agileSTREAM</p> */}
                    <img src={Img1} className={cs.img_group} />

                    </div>

                    <div className={cs.FlexItem}   onClick={()=>navigate("/services/#processStream")}   >
                    {/* <p className={cs.title} >processSTREAM</p> */}
                    <img src={Img2} className={cs.img_group} />

                    </div>
                    <div className={cs.FlexItem}   onClick={()=>navigate("/services/#scrStream")}   >
                    {/* <p className={cs.title} >processSTREAM</p> */}
                    <img src={Img5} className={cs.img_group2} />

                    </div>
                </>
                :
                <>
                    <div className={cs.FlexItem}  onClick={()=>navigate("/services/#secureStream")}  >
                    {/* <p className={cs.title} >secureSTREAM</p> */}
                    <img src={Img3} className={cs.img_group} />

                    </div>

                    <div className={cs.FlexItem}   onClick={()=>navigate("/services/#designStream")}   >
                    {/* <p className={cs.title} >designSTREAM</p> */}
                    <img src={Img4} className={cs.img_group} />

                    </div>
                </>
                }
                    </div>
                </div>
            </div>

            {selected === "managment_consulting" ?
            <>
            <AgileStream updateState={updateState}  />
            <ProcessStream updateState={updateState}  />
            <SrcStream updateState={updateState}  />
            </> :
            <>
            <SecureStream updateState={updateState} />
            <DesignStream updateState={updateState}  />
            </>
            }
            <Footer />
                <div onClick={() =>
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })}
                    className="top" >
                    <img src={Top} style={{ width: 15, filter: "brightness(10)" }} />
                </div>
        </div>
        </>
    );
};

export default Consulting_Services;
